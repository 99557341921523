/* You can add global styles to this file, and also import other style files */
:root {
    --main-color: #2A3496;
    --secondary-color: #F2B92A;
  }

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }


section{
    box-sizing: border-box;
    margin-top: 30px;
    padding: 20px;
}

section p{
    padding: 10px;
}

.flexWrap{
    display: flex;
    flex-wrap: wrap;
}

.div3cajas{
    width: 100%;
}

.titles{
    font-size: 1.2rem;
    font-weight: bold;
}



.card1{
    background-color: rgba(26, 46, 53, 0.93);
    width: 90%;
    border-radius: 10px;
    padding: 15px;
    margin-bottom :20px;
    color:white;
    display: flex;
    align-items: center;
}

.card1 .div1{
    width: 80px;
}

.card1 .div2{
    flex:1
}

.cardContainer1{
    display:flex; 
    flex-wrap: wrap; 
    justify-content: space-around; 
    margin-top: 30px;
}

.hideMovile{
    display: none;
}

@media screen and (min-width: 767px) {
    .div3cajas{
        width: 33.33%;
    }

    .card1{        
        width: 40%;        
    }

    section{
        margin: 0 auto;
        margin-top: 40px;
        width: 80%;
        max-width: 900px;
    }

        
    .hideMovile{
        display: block;
    }
  }